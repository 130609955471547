<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Operational Checklist Template Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      excelFields: {},
      tableData: [],
      templateArr: [],
      restBranchArr: [],
      restBranchID: "",
      branches: [],
      branchID: "",
      templateID: "",
      restaurantBranchID: this.$storageData.profile.restBranchID,
      city: "",
      cityArr: [],
      countryArr: [],
      country: "",
      brandArr: [],
      brandData: "",
      daterange: [],

      title: "Operational Checklist Report",
      items: [
        {
          text: "View",
        },
        {
          text: "Operational Checklist Report",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",

          sortable: false,
        },
        {
          key: "restaurant",
          sortable: true,
        },
        {
          key: "templateTitle",
          sortable: true,
        },
        {
          key: "totalAchieved",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "totalSelfScore",
          sortable: true,
          tdClass: "align-right",
        },
        // {
        //   key:"createdBy",
        //   sortable:true,
        // },
        // {
        //   key: "created",
        //   sortable: true,
        // },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    // this.getRestaurantTemplates();

    if (this.$storageData.profile.empTypeID == 11) {
      this.getRestaurantBrandsByCompanyID();
      this.getRestaurantCountry();
    } else if (this.$storageData.profile.empTypeID == 2) {
      this.readRestaurantBranchData();
      this.getRestaurantCountry();
    } else {
      this.getAllRestaurantBranches();
      this.restaurantBranchID =
      this.$storageData.profile.restBranchID > 0 ? this.$storageData.profile.restBranchID : 0;
    }

    // this.readComplianceReportData(this.restaurantBranchID);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

     downloadSample(){
            this.axios.post('exportExcel', {
              exportData: this.tableData,
              page:"OperationalChecklist"
          },{
             responseType: 'blob'
        }).then((response) => {
              const url = URL.createObjectURL(new Blob([response.data], {
                  type: 'application/vnd.ms-excel'
              }))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'operational-checklist.xlsx')
              document.body.appendChild(link)
              link.click()
          })
  },

    readComplianceReportData() {
      // console.log("id1" + this.restaurantBranchID);
      // console.log("id2" + this.restBranchID.restBranchID);
      
      var restBranchID = this.restaurantBranchID;
      if(restBranchID==""&&(this.$storageData.profile.empTypeID == 11||this.$storageData.profile.empTypeID == 3)){
        return;
      }
      this.axios
        .post(this.$loggedRole+"/viewAllComplianceReports", {
          restBranchID: restBranchID,
          daterange: this.daterange,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.excelFields = {};
          this.tableData = response.data.data;
          this.fields.forEach((element) => {
            // console.log(element.key);
            var key = element.key;
            // var tempObj = {key: key};
            this.excelFields["" + key + ""] = key;
            // console.log(tempObj);
            // tempExcelFields = {tempExcelFields, tempObj};
          });
          //this.
          //console.log((response));
        });
    },

    getRestaurantTemplates() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantTemplates", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((result) => {
          this.templateArr = result.data.data;
        });
    },
    getRestaurantBrandsByCompanyID() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
          companyID: this.$storageData.profile.companyID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((response) => {
          this.brandArr = response.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },

    getAllRestaurantBranches() {
      this.axios
        .post(this.$loggedRole+"/getAllRestaurantBranches", {
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((result) => {
          this.restBranchArr = result.data.data;
        });
    },

    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", {
          restID: this.$storageData.profile.restID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((response) => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getRestaurantCities();
        });
    },

    getRestaurantCities() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID: this.$storageData.profile.restID,
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          this.readRestaurantBranchData();
        });
    },

    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID: this.brandData
            ? this.brandData.restID
            : this.$storageData.profile.restID,
          restBranchID:
            this.$storageData.profile.empTypeID == 2 ||
            this.$storageData.profile.empTypeID == 11
              ? 0
              : this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          city: "",
        })
        .then((response) => {
          this.restBranchArr = response.data.data;
          this.restBranchID = this.restBranchArr ? this.restBranchArr[0] : "";
          this.restaurantBranchID = this.restBranchID
            ? this.restBranchID.restBranchID
            : "";
          this.readComplianceReportData();
        });
    },

    onchangeBranch() {
      this.restaurantBranchID =
        this.restBranchID &&
        this.restBranchID != "" &&
        this.restBranchID.restBranchID > 0
          ? this.restBranchID.restBranchID
          : this.$storageData.profile.restBranchID;
      // this.getRestaurantDashboard();
    },

    applyFilter() {
      // if(this.daterange.includes(null))
      //     {
      //       this.daterange = [];
      //     }
      this.readComplianceReportData();
    },

    clearFilter() {
      this.daterange = [];
      //  this.country="";
      //  this.city="";
      //  this.brandData="";
      this.restaurantBranchID = "";
      this.readRestaurantBranchData();
      this.readComplianceReportData();

     if (this.$storageData.profile.empTypeID == 11) {
      // this.getRestaurantBrandsByCompanyID();
      // this.getRestaurantCountry();
    } else if (this.$storageData.profile.empTypeID == 2) {
      // this.readRestaurantBranchData();
      // this.getRestaurantCountry();
    } else {
      this.getAllRestaurantBranches();
      this.restaurantBranchID =
      this.$storageData.profile.restBranchID > 0 ? this.$storageData.profile.restBranchID : 0;
    }

    },
  },
  middleware: "authentication",
};
</script>





<template>
  <Layout>
    <PageHeader :items="items" />
    <!--FILTER START------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div
            class="col-md-2"
            style="width: 11%"
            v-if="
              this.$storageData.profile.empTypeID == 2 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Select Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              track-by="country"
              label="country"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Cities List-->
          <div
            class="col-md-2"
            style="width: 11%"
            v-if="
              this.$storageData.profile.empTypeID == 2 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              track-by="city"
              label="city"
              :show-labels="false"
            ></multiselect>
          </div>

          <!-- <div class="row mt-4"> -->
          <div class="col-md-2" v-if="this.$storageData.profile.empTypeID == 11">
            <label>Select Restaurant Brand</label>
            <multiselect
              v-model="brandData"
              :options="brandArr"
              track-by="restID"
              label="name"
              :show-labels="false"
              @input="readRestaurantBranchData()"
            ></multiselect>
          </div>
          <div class="col-md-2">
            <label class="form-label" for="formrow-endTime-input"
              >Select Restaurant Branch</label
            >
            <!-- <multiselect v-model="templateID"  :options="templateArr" track-by="complianceTemplateID" label="templateName"
                  @input="readComplianceReportData"></multiselect> -->

            <multiselect
              v-model="restBranchID"
              :options="restBranchArr"
              track-by="restBranchID"
              label="branchName"
              :show-labels="false"
              @input="onchangeBranch"
            ></multiselect>
          </div>
          <!-- Date range -->
          <div class="col-md-2" style="width: 20%">
            <label>Select Date</label>
            <date-picker
              v-model="daterange"
              append-to-body
              lang="en"
              confirm
            ></date-picker>
          </div>

          <!-- Apply filter -->
          <div class="col-md-2" style="width: auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->
          <!-- Clear filter -->
          <div class="col-md-2" style="width: 10%">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->

          <!-- Date range END-->
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
              <div role="group" class="btn-group">
                <button type="button" class="btn btn-themeBrown" @click="downloadSample();">Excel</button> 
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show &nbsp;&nbsp;
                  <b-form-select
                    style="margin-left: 5px; margin-right: 5px"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;&nbsp;&nbsp; entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-4 row">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-end"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ms-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              striped
              hover
              outlined
              bordered
              :items="tableData"
              :fields="fields"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(Action)="data">
                <router-link
                  v-if="
                    data.item.isEdit == 1 &&
                    data.item.isOpen == 0 &&
                    $storageData.profile.empTypeID == 1
                  "
                  :to="{
                    name: 'operationalReportForm',
                    params: {
                      id: data.item.complianceTemplateID,
                      type: 'edit',
                    },
                  }"
                >
                  <button class="btn btn-themeBrown" style="padding: 1px 9px">
                    Edit
                  </button>
                </router-link>
                <router-link
                  v-if="data.item.isOpen == 1 && $storageData.profile.empTypeID == 1"
                  :to="{
                    name: 'operationalReportForm',
                    params: {
                      id: data.item.complianceTemplateID,
                      type: 'view',
                    },
                  }"
                >
                  <button class="btn btn-themeYellow" style="padding: 1px 9px">
                    View
                  </button>
                </router-link>

                <router-link
                  v-if="data.item.isEdit == 0"
                  :to="{
                    name: 'operationalReportForm',
                    params: {
                      id: data.item.complianceTemplateID,
                      type: 'create',
                    },
                  }"
                >
                  <button class="btn btn-themeYellow" style="padding: 1px 9px">
                    Create
                  </button>
                </router-link>

                <!-- <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i> -->
              </template>

              <template v-slot:cell(totalAchieved)="data">
                {{ data.item.totalAchieved }} %
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </Layout>
</template>
<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>